import React from "react"
import PropTypes from "prop-types"

import { button as buttonStyle } from "./index.css"

const Button = ({ id, type, name, value, children }) => (
  <button
    className={buttonStyle}
    id={id}
    type={type}
    name={name}
    value={value}
  >{children}</button>
)

Button.propTypes = {
  id: PropTypes.string,
  type: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
}

Button.defaultProps = {
  type: 'submit',
}

export default Button
