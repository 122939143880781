import React from "react"
import PropTypes from "prop-types"

import { input } from "./index.css"

const Input = ({ id, type, name, value, required }) => (
  <input
    className={input}
    id={id}
    type={type}
    name={name}
    value={value}
    required={required}
  />
)

Input.propTypes = {
  id: PropTypes.string,
  type: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  required: PropTypes.bool,
}

Input.defaultProps = {
  type: 'text',
  required: false,
}

export default Input
