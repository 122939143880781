import React from "react"
import PropTypes from "prop-types"

import { textarea } from "./index.css"

const Textarea = ({ id, name, required, rows, cols }) => (
  <textarea
    className={textarea}
    id={id}
    name={name}
    required={required}
    rows={rows}
    cols={cols}
  />
)

Textarea.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
  rows: PropTypes.number,
  cols: PropTypes.number,
}

Textarea.defaultProps = {
  type: 'text',
  required: false,
  rows: 10,
  cols: 50,
}

export default Textarea
