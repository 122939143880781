import React from "react"

import Header from "../header"
import Footer from "../footer"

import { defaultLayout, body } from "./index.css"

const bodyClassNames = `container ${body}`

const DefaultLayout = ({ siteTitle, author, children }) => {
  return (
    <div className={defaultLayout}>
      <Header siteTitle={siteTitle} />
      <main className={bodyClassNames}>
        {children}
      </main>
      <Footer copyright={author} />
    </div>
  )
}

export default DefaultLayout;
