import React from "react"

import Input from '../input'
import Textarea from '../textarea'
import Button from '../button'

import {
  contactForm,
  title,
  fieldSet,
  label,
  labelText,
  action
} from "./index.css"

const FORM_ID = 'contact'
const NAME_ID = 'name'
const EMAIL_ID = 'email'
const MESSAGE_ID = 'message'

const ContactForm = () => (
  <>
    <h1 className={title}>
      お問い合わせ
    </h1>
    <form
      id={FORM_ID}
      className={contactForm}
      name={FORM_ID}
      action="/thanks"
      method="post"
      data-netlify="true"
      data-netlify-honeypot="bot-field"
    >
      <Input type="hidden" name="form-name" value={FORM_ID} />
      <Input type="hidden" name="bot-field" />
      <div className={fieldSet}>
        <label
          className={label}
          htmlFor={NAME_ID}
        >
          <span className={labelText}>お名前</span>
        </label>
        <Input id={NAME_ID} name="name" required={true} />
      </div>
      <div className={fieldSet}>
        <label
          className={label}
          htmlFor={EMAIL_ID}
        >
          <span className={labelText}>メールアドレス</span>
        </label>
        <Input id={EMAIL_ID} type="email" name="email" required={true} />
      </div>
      <div className={fieldSet}>
        <label
          className={label}
          htmlFor={MESSAGE_ID}
        >
          <span className={labelText}>内容</span>
        </label>
        <Textarea id={MESSAGE_ID} name="message" required={true} />
      </div>
      <div className={action}>
        <Button
          id={FORM_ID}
          type="submit"
        >
          送信する
        </Button>
      </div>
    </form>
  </>
)

export default ContactForm
