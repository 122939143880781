import React from "react"
import { graphql } from "gatsby"

import DefaultLayout from "../components/default-layout"
import Meta from "../components/meta"
import ContactForm from "../components/contact-form"

const ContactPage = ({ data }) => {
  const { title, author, siteUrl } = data.site.siteMetadata
  return (
    <DefaultLayout
      siteTitle={title}
      author={author}
    >
      <Meta
        title="お問い合わせ"
        meta={[
          { name: "robots", content: "noindex, nofollow" },
          { name: "googlebot", content: "noindex, nofollow" },
        ]}
        link={[
          { rel: "canonical", href: siteUrl }
        ]}
      />
      <ContactForm />
    </DefaultLayout>
  )
}

export default ContactPage;
export const contactQuery = graphql`
  query contactQuery {
    site {
      siteMetadata {
        title
        author
        siteUrl
      }
    }
  }
`;
